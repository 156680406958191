.App {
	box-sizing: border-box;
	margin: 0px;
	background-color: #f6ecfe;
	background-color: #f6ecfe;
	padding: 0px;
	width: 100%;
	max-width: 100%;
	min-height: 100vh;
	overflow-x: hidden;
	text-align: center;
}
.Navbar-Container {
	display: relative;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 101;
	width: 100%;
	height: 10vh;
}
.Navbar {
	display: flex;
	left: 30px;
	justify-content: center;
	align-items: center;
	z-index: 5;
	margin: 0px;
	box-shadow: 0px 0px 16px 0px #000000;
	background-color: #5d0b98;
	max-width: 100vw;
	height: max(10vh, 100px);
	color: white;
}
.NavbarElem {
	transition: font-size 0.5s ease, color 0.5s ease, margin 0.5s ease;
	margin: 0 30px;
	color: white;
	font-weight: 400;
	font-size: 16pt;
	text-decoration: none;
}
.NavbarElem.Selected {
	margin: 0 26px;
	border-bottom: 2px dashed #f6ecfe;
	color: #f6ecfe;
	font-weight: 600;
	font-size: 20pt;
	text-decoration: solid;
}
.NavbarLogo,
.NavbarLogo-Loading {
	position: absolute;
	top: 10px;
	left: 30px;
	z-index: 101;
	margin-top: 4px;
	border: 2px double rgb(172, 50, 183);
	border-radius: 45px;
	width: 78px;
	height: 78px;
}
.NavbarLogo-Loading {
	position: absolute;
	top: 4px;
	left: 30px;
}
.Loading-Navbar-Circle {
	display: block;
	animation: linear ease-in-out 3s infinite;
	animation-name: loadingNav;
	will-change: transform;
	margin: 0px 30px;
	border: 5px solid white;
	border-radius: 50%;
	width: 40px;
	height: 40px;
}
@keyframes loadingNav {
	0% {
		transform: translateX(0px);
	}
	25% {
		transform: translateX(20vw);
	}
	75% {
		transform: translateX(-20vw);
	}
	100% {
		transform: translateX(0px);
	}
}
.Loading-Header {
	display: inline-block;
	transition: color 0.3s;
	cursor: pointer;
	margin-top: 6vw;
	padding: 0px 0px 20px 0px;
	color: #f5e7ff;
	font-weight: bold;
	font-size: 28pt;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	letter-spacing: 2px;
	text-align: center;
	text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
		2px 2px 0 black;
}
.Loading-Spinner {
	position: absolute;
	top: calc(50vh);
	left: calc(50% - 150px);
	animation: linear 2.8s infinite;
	animation-name: loadingSpin;
	border-radius: 50%;
	width: 300px;
	height: 300px;
}
.Invis {
	display: none;
}
@keyframes loadingSpin {
	0% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(90deg);
	}
	50% {
		transform: rotate(180deg);
	}
	75% {
		transform: rotate(270deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
/* Width Scaling */
@media (max-width: 800px) {
	.NavbarElem {
		margin: 0 10px;
		font-size: 14pt;
	}
	.NavbarElem.Selected {
		margin: 0 10px;
		font-size: 17pt;
	}
	.NavbarLogo {
		display: none;
	}
	.NavbarLogo-Loading {
		display: none;
	}
	.Loading-Header {
		margin-top: 10vw;
		font-size: 24pt;
	}
	.Loading-Spinner {
		left: calc(50% - 100px);
		width: 200px;
		height: 200px;
	}
}

@media (max-width: 600px) {
	.Loading-Header {
		margin-top: 14vw;
		font-size: 20pt;
	}
	.Loading-Spinner {
		left: calc(50% - 75px);
		width: 150px;
		height: 150px;
	}
}
@media (max-height: 1040px) {
	.NavbarLogo {
		top: 4px;
	}
}
