.TopDisplay {
	--componentHeight: 300px;
	display: flex;
	position: relative;
	justify-content: flex-start;
	align-items: center;
	gap: 0px;
	z-index: 10;
	margin: calc(var(--componentHeight) / 25) 0px
		calc(var(--componentHeight) / 25) 0px;
	border: black solid 3px;
	border-radius: 10px;
	max-height: var(--componentHeight);
}

.Color-Pallet {
	display: grid;
	grid-template-rows: 1fr 1fr 1fr 1fr;
	gap: 0px;
	z-index: 11;
	margin: 0px;
	padding: 0px;
	max-width: 25px;
	height: var(--componentHeight);
	max-height: var(--componentHeight);
}
.Color-Display {
	display: flex;
	cursor: pointer;
	margin: 0px;
	border: none;
	padding: 0px;
	width: 25px;
	height: calc((var(--componentHeight) / 4));
}

.Color-Display-First {
	cursor: pointer;
	border-top-left-radius: 6px;
}
.Color-Display-Last {
	cursor: pointer;
	border-bottom-left-radius: 6px;
}

.TopImage {
	cursor: pointer;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	width: var(--componentHeight);
	height: var(--componentHeight);
}
.Report-Outfit-Button {
	position: absolute;
	right: 10px;
	bottom: 10px;
	cursor: pointer;
	border: none;
	background-color: rgba(0, 0, 0, 0);
	width: 50px;
	height: 50px;
}
.Report-Image {
	width: 44px;
}
@keyframes pulseRep {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.5);
		background-image: radial-gradient(
			circle,
			#f6ecfe 0%,
			#ae6cdd 70%,
			#5d0b98 100%
		);
	}
	100% {
		transform: scale(1);
	}
}
.Report-Clicked {
	animation: pulseRep 0.5s forwards;
}
/* Position this contianer by moving down a twenth of the component height, and left an eigth of container height */
.Top-Display-Size-Container {
	display: inline-block;
	position: absolute;
	top: 10px;
	left: 20px;
	z-index: 101;
	border: black solid 2px;
	border-radius: 14px;
	background-color: #5d0b98;
}
.Top-Display-Size-Text {
	display: inline-block;
	cursor: default;
	margin: 6px 8px;
	padding: 0px;
	color: white;
	font-weight: bold;
	font-size: 14pt;
}
.TopDisplayInvis {
	display: none;
}

@media (max-width: 1999px) {
	.TopDisplay {
		--componentHeight: 200px;
		margin: 6px 0px;
	}
	.Color-Display,
	.Color-Display-First,
	.Color-Display-Last {
		width: 16px;
	}
	.Top-Display-Size-Text {
		font-size: 11pt;
	}
}

@media (max-width: 500px) {
	.TopDisplay {
		--componentHeight: 138px;
		margin: 2px 0px;
	}
	.Color-Display,
	.Color-Display-First,
	.Color-Display-Last {
		width: 10px;
	}
	.Top-Display-Size-Text {
		font-size: 8pt;
	}
	.Report-Image {
		width: 28px;
	}
	.Report-Outfit-Button {
		width: 36px;
		height: 36px;
	}
}
