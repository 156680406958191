.Home {
	cursor: default;
}
.Home-Hero {
	display: relative;
	padding-top: min(10vh, 180px);
}
.Hero-Background {
	position: absolute;
	z-index: 0;
	width: 100%;
	height: 100%;
}
.Rel-Dummy {
	display: flex;
	position: relative;
	top: 22vh;
	justify-content: center;
	align-items: center;
	z-index: 5;
}
.Hero-Overlay {
	display: flex;
	position: absolute;
	flex-direction: column;
	align-items: start;
	opacity: 80%;
	background-color: black;
	padding: 30px 50px;
	width: 1000px;
}
.Hero-Header {
	display: inline-block;
	opacity: 100%;
	z-index: 5;
	margin: 0px;
	margin-left: 20px;
	color: #f5e7ff;
	font-weight: bold;
	font-size: 62pt;
	font-family: "Roboto", sans-serif;
	letter-spacing: 2px;
	text-align: left;
	text-align: center;
	text-shadow: -2px -2px 0 #5d0b98, 2px -2px 0 #5d0b98, -2px 2px 0 #5d0b98,
		2px 2px 0 #5d0b98;
}
.Hero-Subheader {
	display: inline-block;
	opacity: 100%;
	z-index: 5;
	margin: 0px;
	margin-top: 30px;
	margin-left: 58px;
	padding: 0px;
	color: #f5e7ff;
	font-size: 22pt;
	line-height: 34px;
	font-family: "Courier New", Courier, monospace;
	text-align: left;
	text-shadow: -1px -1px 0 #5d0b98, 1px -1px 0 #5d0b98, -1px 1px 0 #5d0b98,
		1px 1px 0 #5d0b98;
}
.Hero-Button,
.Bottom-Call-To-Action {
	display: inline-block;
	opacity: 100%;
	z-index: 5;
	transition: background-color 0.3s, color 0.3s, box-shadow 0.3s,
		transform 0.3s;
	cursor: pointer;
	margin: max(4vh, 70px) 0px max(4vh, 70px) 30px;
	border: none;
	border-radius: 5px;
	background-color: #5d0b98;
	padding: 15px 20px;
	color: #f5e7ff;
	font-weight: bold;
	font-size: 24pt;
	font-family: "Roboto", sans-serif;
	text-align: center;
	text-shadow: -1px -1px 0 #5d0b98, 1px -1px 0 #5d0b98, -1px 1px 0 #5d0b98,
		1px 1px 0 #5d0b98;
}
.Hero-Button:hover {
	transform: scale(1.1);
	box-shadow: 0px 0px 10px 0px #5d0b98;
	background-color: black;
	color: #5d0b98;
}

.How-It-Works-Wrap {
	display: flex;
	flex-direction: column;
}
.How-It-Works-Intro-Panel {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 3vw;
	background-color: #f5e7ff;
	padding-top: 2vh;
	padding-bottom: 10vh;
}
.How-It-Works-Header {
	display: inline-block;
	transition: color 0.3s;
	cursor: pointer;
	padding: 0px 0px 20px 0px;
	color: #f5e7ff;
	font-weight: bold;
	font-size: max(2.2vw, 34pt);
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	letter-spacing: 2px;
	text-align: center;
	text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
		2px 2px 0 black;
}
.How-It-Works-Header:hover {
	color: #c9ace1;
}
.Down-Arrow-Home {
	width: 80px;
	height: 80px;
}
.Wave-Container {
	margin: 0px;
	padding: 0px;
}
.Wave-Panel-Interface {
	background-color: #f5e7ff;
}
.Step-One-Aggregation-Panel,
.Step-Two-Coloring-Panel,
.Step-Three-Generation-Panel {
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 3vw;
	background-color: #c9ace1;
	padding-top: 70px;
	padding-bottom: 200px;
}
.Step-One-Header,
.Step-Two-Header,
.Step-Three-Header {
	display: inline-block;
	position: relative;
	left: -120px;
	margin: 0px;
	padding-top: 2vh;
	padding-bottom: 6vh;
	color: #f6ecfe;
	font-weight: bold;
	font-size: max(1.8vw, 30pt);
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	letter-spacing: 2px;
	text-align: start;
	text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
		2px 2px 0 black;
}
.Step-Span {
	margin-right: 12px;
	color: black;
	text-shadow: -1px -1px 0 #f6ecfe, 1px -1px 0 #f6ecfe, -1px 1px 0 #f6ecfe,
		2px 2px 0 #f6ecfe;
}
.Bullet-Span {
	margin-right: 1vw;
	color: #5d0b98;
}
.Bullet-Text-Emph {
	color: #5d0b98;
}
.Step-One-Image,
.Step-Two-Image,
.Step-Three-Image {
	display: inline;
	position: absolute;
	top: 10vh;
	left: 30px;
	border: 4px double black;
	border-radius: 40px;
	background-color: #f5e7ff;
	padding: 40px;
	width: 360px;
	height: 360px;
}
.Step-One-Text,
.Step-Two-Text {
	margin: 0px;
	margin-top: 3vh;
	margin-left: 30vw;
	color: black;
	font-weight: 600;
	font-size: max(1vw, 18pt);
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	text-align: left;
	text-shadow: -1px -1px 0 #f5e7ff, 1px -1px 0 #f5e7ff, -1px 1px 0 #f5e7ff,
		1px 1px 0 #f5e7ff;
}
.Step-One-Image-Panel {
	display: flex;
	position: relative;
	top: -20vh;
	left: 36vw;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 6px;
}
.Step-One-Svg {
	width: 120px;
	height: 120px;
}
.Step-One-Svg-Shoe,
.Step-One-Svg-Shoe-Two {
	display: inline;
	position: relative;
	transform: scaleX(-1);
	width: 90px;
	height: 90px;
}
.Step-One-Svg-Shoe {
	left: -45px;
}
.Step-One-Svg-Shoe-Two {
	top: -96px;
	left: 47px;
	transform: scaleX(1);
}

.Step-Two-Coloring-Panel {
	background-color: #5d0b98;
}
.Wave-Panel-Interface-Two {
	background-color: #c9ace1;
}
.Spectrum-Bar {
	display: inline;
	position: absolute;
	top: 26vh;
	right: -60px;
	rotate: 90deg;
	border: 4px double black;
	width: 400px;
	height: 60px;
}
.Bullet-Span-Two {
	margin-right: 1vw;
	color: #c9ace1;
}
.Bullet-Text-Emph-Two {
	color: #ffff99;
}
.Step-Two-Text {
	color: #f6ecfe;
	text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
		1px 1px 0 black;
}
.Wave-Panel-Interface-Three {
	background-color: #5d0b98;
}
.Step-Three-Generation-Panel {
	background-color: #000000;
}
.Step-Three-Header {
	text-shadow: -1px -1px 0 #5d0b98, 1px -1px 0 #5d0b98, -1px 1px 0 #5d0b98,
		2px 2px 0 #5d0b98;
}
.Bullet-Text-Emph-Three {
	text-shadow: -1px -1px 0 #5d0b98, 1px -1px 0 #5d0b98, -1px 1px 0 #5d0b98,
		2px 2px 0 #5d0b98;
}
.Step-Three-Image {
	border: 4px double #5d0b98;
}
.Bottom-Call-To-Action {
	transition: transform 0.2s, box-shadow 0.2s;
	margin-top: max(6vh, 100px);
	background-color: #f5e7ff;
	color: black;
	text-shadow: red 1px 1px 0px;
}
.Bottom-Call-To-Action:hover {
	transform: scale(1.1);
	box-shadow: 0px 0px 10px 0px #5d0b98;
}
.Wave-Panel-Interface-Four {
	background-color: #000000;
}

/* responsive width */

@media (max-width: 2160px) {
	.Step-One-Image,
	.Step-Two-Image,
	.Step-Three-Image {
		padding: 30px;
		width: 300px;
		height: 300px;
	}

	.Step-One-Header,
	.Step-Two-Header,
	.Step-Three-Header {
		left: -60px;
	}
	.Step-One-Text,
	.Step-Two-Text {
		margin-left: 32vw;
	}
}

@media (max-width: 1900px) {
	.Step-One-Header,
	.Step-Two-Header,
	.Step-Three-Header {
		left: 0px;
	}
	.Step-One-Text,
	.Step-Two-Text {
		margin-left: 32vw;
	}
	.Spectrum-Bar {
		display: none;
	}
	.Hero-Overlay {
		margin-bottom: 20px;
		width: 800px;
	}
	.Hero-Header {
		font-size: 50pt;
	}
	.Hero-Subheader {
		font-size: 18pt;
	}
}

@media (max-width: 1700px) {
	.Step-One-Image,
	.Step-Two-Image,
	.Step-Three-Image {
		top: 14vh;
		padding: 25px;
		width: 200px;
		height: 200px;
	}
	.Step-One-Header,
	.Step-Two-Header,
	.Step-Three-Header {
		left: 0px;
	}
	.Step-One-Text,
	.Step-Two-Text {
		margin-left: 28vw;
		font-size: 16pt;
	}
	.Spectrum-Bar {
		display: none;
	}
}

@media (max-width: 1370px) {
	.Step-One-Image,
	.Step-Two-Image,
	.Step-Three-Image {
		top: 18vh;
		width: 160px;
		height: 160px;
	}
	.Step-One-Text,
	.Step-Two-Text {
		margin-left: 26vw;
		font-size: 15pt;
	}
}

@media (max-width: 1220px) {
	.Step-One-Text,
	.Step-Two-Text {
		margin-left: 38vw;
		max-width: 500px;
		line-height: 34px;
		text-indent: -23px;
	}
	.Step-One-Image,
	.Step-Two-Image,
	.Step-Three-Image {
		top: 27vh;
	}
	.Step-One-Header,
	.Step-Two-Header,
	.Step-Three-Header {
		left: -20px;
	}
}
@media (max-width: 1000px) {
	.Hero-Overlay {
		width: 630px;
	}
	.Hero-Header {
		font-size: 40pt;
	}
	.Hero-Subheader {
		font-size: 14pt;
	}
	.Hero-Button {
		margin-top: 40px;
		margin-bottom: 20px;
		font-size: 20pt;
	}
}

@media (max-width: 880px) {
	.Step-One-Text,
	.Step-Two-Text {
		margin-left: 36vw;
		max-width: 410px;
		line-height: 34px;
	}
	.Step-One-Image,
	.Step-Two-Image,
	.Step-Three-Image {
		top: 30vh;
		width: 120px;
		height: 120px;
	}
	.Step-One-Header,
	.Step-Two-Header,
	.Step-Three-Header {
		margin-bottom: 16px;
		font-size: 24pt;
	}
	.Bottom-Call-To-Action {
		font-size: 18pt;
	}
}

@media (max-width: 790px) {
	.Hero-Overlay {
		width: 500px;
	}
	.Hero-Header {
		font-size: 36pt;
		letter-spacing: 1px;
	}
	.Hero-Subheader {
		margin-top: 16px;
		font-size: 13pt;
		line-height: 26px;
	}
	.Hero-Button {
		margin-top: 40px;
		margin-bottom: 20px;
		font-size: 18pt;
	}
	.Step-One-Header,
	.Step-Two-Header,
	.Step-Three-Header {
		left: 0px;
		margin-bottom: 0px;
		font-size: 22pt;
	}
	.Step-One-Text,
	.Step-Two-Text {
		margin-left: 38vw;
		max-width: 300px;
		line-height: 34px;
	}
	.Step-One-Image,
	.Step-Two-Image,
	.Step-Three-Image {
		top: 36vh;
		width: 90px;
		height: 90px;
	}
}
@media (max-width: 650px) {
	.Step-One-Header,
	.Step-Two-Header,
	.Step-Three-Header {
		left: 0px;
		margin-bottom: 0px;
		font-size: 20pt;
	}
	.How-It-Works-Header {
		font-size: 28pt;
	}
	.Hero-Overlay {
		width: 400px;
	}
	.Hero-Header {
		margin-left: 0px;
		font-size: 28pt;
	}
	.Hero-Subheader {
		font-size: 12pt;
		line-height: 24px;
	}
	.Hero-Button {
		margin-top: 30px;
		font-size: 16pt;
	}
	.Step-One-Text,
	.Step-Two-Text {
		margin-left: 44vw;
	}
}
@media (max-width: 580px) {
	.Step-One-Header,
	.Step-Two-Header,
	.Step-Three-Header {
		font-size: 17pt;
	}
	.How-It-Works-Header {
		font-size: 24pt;
	}
	.Step-One-Image,
	.Step-Two-Image,
	.Step-Three-Image {
		top: 40vh;
		width: 60px;
		height: 60px;
	}
	.Step-One-Text,
	.Step-Two-Text {
		margin-left: 44vw;
		max-width: 270px;
	}
}
@media (max-width: 500px) {
	.Step-One-Header,
	.Step-Two-Header,
	.Step-Three-Header {
		font-size: 16pt;
		line-break: none;
	}
	.How-It-Works-Header {
		font-size: 18pt;
	}
	.Down-Arrow-Home {
		display: none;
	}
	.Hero-Overlay {
		width: 200px;
	}
	.Hero-Header {
		font-size: 18pt;
	}
	.Hero-Subheader {
		left: 0px;
		margin-left: 10px;
		font-size: 10pt;
	}
	.Hero-Button {
		margin-top: 20px;
		margin-left: 0px;
		font-size: 14pt;
	}
	.Step-One-Text,
	.Step-Two-Text {
		margin-left: 10vw;
	}
	.Step-One-Image,
	.Step-Two-Image,
	.Step-Three-Image {
		display: none;
	}
	.Step-Span {
		display: none;
	}
}
@media (max-width: 370px) {
	.Step-One-Text,
	.Step-Two-Text {
		margin-left: 10vw;
		max-width: 220px;
		text-indent: -40px;
	}
}
/* responsive height */
@media (max-height: 1240px) {
	.Rel-Dummy {
		top: 24vh;
	}
}
@media (max-height: 1140px) {
	.Rel-Dummy {
		top: 26vh;
	}
}
@media (max-height: 1050px) {
	.Hero-Overlay {
		padding: 30px 40px;
	}
	.Hero-Button {
		margin-top: 40px;
		margin-bottom: 40px;
	}
}
@media (max-height: 900px) {
	.Rel-Dummy {
		top: 28vh;
	}
}
@media (max-height: 840px) {
	.Rel-Dummy {
		top: 32vh;
	}
}
@media (max-height: 790px) {
	.Rel-Dummy {
		top: 36vh;
	}
}
@media (max-height: 720px) {
	.Rel-Dummy {
		top: 37vh;
	}
}
@media (max-height: 680px) {
	.Rel-Dummy {
		top: 38vh;
	}
}
@media (max-height: 640px) {
	.Rel-Dummy {
		top: 42vh;
	}
}
@media (max-height: 610px) {
	.Rel-Dummy {
		top: 46vh;
	}
}
@media (max-height: 560px) {
	.Rel-Dummy {
		top: 52vh;
	}
}
