.carousel {
	display: inline-block;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 0;
	box-shadow: 0px 0px 10px 0px #5d0b98;
	width: 100%;
	max-width: 100%;
	height: max(50vh, 540px);
}

.SlideImage {
	display: inline-block;
	box-sizing: border-box;
	margin: 0px;
	border: 0px none;
	padding: 0px;
	width: 100%;
	max-width: 100%;
	height: max(50vh, 540px);
	pointer-events: none;
}
