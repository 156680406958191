.Keywords {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 20px;
	width: 300px;
}
.Keyword-Input-Div {
	display: flex;
	position: relative;
	left: -22px;
	flex-direction: row;
	align-items: center;
}
.Keyword-Input {
	z-index: 3;
	margin: 20px 0px 20px 0px;
	box-shadow: 1px 1px 1px 1px #5d0b98;
	background-color: #f6ecfe;
	padding-top: 4px;
	color: black;
	font-size: 12pt;
	font-family: "Roboto", sans-serif;
	text-shadow: -2px -2px 0 #f5e7ff, 2px -2px 0 #f5e7ff, -2px 2px 0 #f5e7ff,
		4px 4px 0 #f5e7ff;
}
.Add-Button {
	display: inline-block;
	transition: background-color 0.3s, color 0.3s, border-radius,
		text-shadow 0.3s, border-width 0.3s;
	cursor: pointer;
	margin-right: 20px;
	box-shadow: 1px 1px 1px 0px #5d0b98;
	border: 1px ridge #5d0b98;
	background-color: #f5e7ff;
	padding: 4px 12px 6px 12px;
	color: #f5e7ff;
	font-weight: bold;
	font-size: 11pt;
	font-family: "Roboto", sans-serif;
	text-align: center;
	text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
		1px 1px 0 black;
}
.Add-Button:hover {
	border-color: #f5e7ff;
	background-color: black;
	color: #5d0b98;
	text-shadow: -1px -1px 0 #f5e7ff, 1px -1px 0px #f5e7ff, -1px 1px 0 #f5e7ff,
		1px 1px 0 #f5e7ff;
}
.Keyword-Input:focus {
	outline: none;
	box-shadow: 1px 1px 1px 2px #5d0b98;
}

.Selected-Word-Div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
	transition: background-color 0.15s, color 0.15s, border-radius 0.15s,
		text-shadow 0.15s, border-width 0.15s;
	cursor: pointer;
	border: 1px ridge #5d0b98;
	border-radius: 5px;
	background-color: #f5e7ff;
	padding: 0px 4px 0px 4px;
	color: #f5f5f5;
	font-weight: bold;
	font-size: 12pt;
	font-family: "Roboto", sans-serif;
	text-align: center;
	text-shadow: -1px -1px 0 black, 1px -1px 0px black, -1px 1px 0 black,
		1px 1px 0 black;
}
.Selected-Word-Div:hover {
	border-color: #5d0b98;
	background-color: lightgray;
	color: black;
	text-decoration: line-through;
	text-shadow: -1px -1px 0 #f5e7ff, 1px -1px 0 #f5e7ff, -1px 1px 0 #f5e7ff,
		1px 1px 0 #f5e7ff;
}

.Selected-Words {
	display: flex;
	position: absolute;
	bottom: -20px;
	left: 56px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 18px;
	margin: 4px;
}
.Selected-Word {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 18px;
	z-index: 10;
	margin: 4px;
}
.Brand-x-Tag {
	width: 20px;
	height: 20px;
	color: white;
}

@media (max-width: 815px) {
	.Keyword-Input {
		width: 160px;
		font-size: 10pt;
	}
	.Add-Button {
		font-size: 10pt;
	}
	.Keywords {
		gap: 4px;
	}
	.Selected-Word {
		font-size: 10pt;
	}
}

@media (max-width: 690px) {
	.Keyword-Input {
		margin-top: 0px;
		width: 120px;
		font-size: 11pt;
	}
	.Add-Button {
		position: relative;
		top: -10px;
		font-size: 11pt;
	}
	.Keyword-Input-Div {
		position: relative;
		left: 30px;
	}
	.Selected-Words {
		gap: 6px;
	}
	.Selected-Word-Div {
		gap: 2px;
		font-size: 11pt;
	}
}
